<template>
  <sub-page
      v-model="$store.state.dashboard.pageData"
      :title="project.name || ''"
  >
    <template #default>

      <ws-accordion
          v-if="folders.length > 0"
          :items="folders"
          :key="updateTrigger"
      >
        <template #header="{item}">
          <h4>
            {{ item.name }} ({{item.events_count}})
          </h4>
        </template>
        <template #item="{item}">
          <ws-data-table
              :set="project = item"
              v-if="getProjectEvents(item.value).length > 0"
              :items="getProjectEvents(item.value)"
              :headers="eventHeaders"
              dense
              :context-actions-select="contextActionsSelect"
              @itemSelect="selectedItem = $event"
          >
            <template #item="{item , contextAction}">
              <project-event
                  :context-action="contextAction"
                  :entity="item"
                  @addItem="addEvent(project.value)"
                  :key="item.uuid"  />
            </template>

          </ws-data-table>


          <v-btn @click="addEvent(item.value)" block text class="noCaps">
            <v-icon>mdi-plus</v-icon>
            {{  $t("Task")  }}
          </v-btn>
        </template>
      </ws-accordion>

      <v-btn @click="addChapter(i+1)" block text class="noCaps">
        <v-icon>mdi-plus</v-icon>
        {{ $t('Topic') }}
      </v-btn>

    </template>

    <template #dialog>
      <ws-dialog
        v-model="displayChapterDialog"
        :title="$t('Edit topic')"
        @save="addTopic"
      >
        <ws-text-field
          v-model="entityData.name"
          :label="$t('Name')"
          :placeholder="$t('EnterName')"
        />
      </ws-dialog>
    </template>









  </sub-page>
</template>

<script>
import {mapActions, mapState} from "vuex";
import projectEvent from "@/components/pages/adminDashboard/tasks/projectEvent";

export default {
  name: "adminProject",
  components : {
    projectEvent
  },
  props : {
    uuid : {
      type : String,
      default : null
    }
  },
  data() {
    return {

      entityData : {},
      displayChapterDialog : false,
      updateTrigger : 0,
      selectedItem : {},
      displayDialog : false,

      project : {},
      items : [],
      folders : [],
    }
  },
  computed : {
    ...mapState('adminCrmSystem' , ['completionFilter']),

    eventHeaders() {
      return [
        {   value : 'status' , width : "10" },
        {  text : this.$t('Date') , value : 'date_created'  },
        {  text : this.$t('Name') , value : 'name' , sort : false},
        {  text : this.$t('Priority') , value : 'priority' },
        {  text : this.$t('Deadline') , value : 'date_scheduled' },
        {  text : this.$t('Manager') , value : 'manager' },
        {   value : 'files' , width : "10" },
      ]
    },
    contextActionsSelect() {
      return [
        {
          text : this.$t('View') ,
          icon : 'view',
          action : () => this.displayDialog = true
        },
        {
          text : this.$t('Delete') ,
          icon : 'mdi-delete',
          action : this.deleteItem
        }
      ]
    }
  },
  watch : {
    completionFilter() {
      this.updateTrigger++
    }
  },
  methods : {
    ...mapActions('adminCrmSystem', [
        'GET_PROJECT',
        'ADMIN_CRM_ADD_BUSINESS_EVENT',
        'ADMIN_CRM_DELETE_BUSINESS_EVENT',
        'ADD_PROJECT'
    ]),
    async deleteItem() {
      let result = await this.ADMIN_CRM_DELETE_BUSINESS_EVENT(this.selectedItem.uuid)
      if ( !result ) {
        return
      }
      let index = this.items.findIndex(el => el.uuid === this.selectedItem.uuid )
      if ( index === -1 ) {
        return
      }
      this.items.splice(index, 1)
      this.notify(this.$t('TaskDeleted'))
    },
    async addEvent(uuid) {
      let data = {
        type : 'task',
        project_id : uuid
      }
      let result =  await this.ADMIN_CRM_ADD_BUSINESS_EVENT(data)
      if  (!result ) { return }
      this.items.push(result)

    },
    async addChapter() {
      let data = {
        name : 'New Topic',
        project_id : this.uuid
      }
      let result = await this.ADD_PROJECT(data)
      if ( !result ) {
        return
      }

      this.folders.push({
        name : result.name,
        value : result.uuid,
        icon : "mdi-cog",
        expand : true,
        expanded : true,
        events_count : 0
      })
      this.notify(this.$t('ProjectCreated') , 'success')
      this.displayDialog = false
      this.updateTrigger++
    },

    //technical
    getProjectEvents(uuid) {
      let items = this.items
      if ( items.length === 0) {
        return []
      }

      items = items.filter(el=>el.project_id === uuid)

      if ( this.$store.state.adminCrmSystem.completionFilter === 'new' ) {
        items = items.filter(el => !el.is_completed)
      }
      if ( this.$store.state.adminCrmSystem.completionFilter === 'completed' ) {
        items = items.filter(el => el.is_completed)
      }


      return items

    },
    async initPage() {
      let result = await this.GET_PROJECT(this.uuid)
      if ( !result ) {
        return
      }
      this.project = result.project
      this.items = result.events
      this.folders = result.folders
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>