var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sub-page',{attrs:{"title":_vm.project.name || ''},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.folders.length > 0)?_c('ws-accordion',{key:_vm.updateTrigger,attrs:{"items":_vm.folders},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var item = ref.item;
return [_c('h4',[_vm._v(" "+_vm._s(item.name)+" ("+_vm._s(item.events_count)+") ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [(_vm.getProjectEvents(item.value).length > 0)?_c('ws-data-table',{attrs:{"set":_vm.project = item,"items":_vm.getProjectEvents(item.value),"headers":_vm.eventHeaders,"dense":"","context-actions-select":_vm.contextActionsSelect},on:{"itemSelect":function($event){_vm.selectedItem = $event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var contextAction = ref.contextAction;
return [_c('project-event',{key:item.uuid,attrs:{"context-action":contextAction,"entity":item},on:{"addItem":function($event){return _vm.addEvent(_vm.project.value)}}})]}}],null,true)}):_vm._e(),_c('v-btn',{staticClass:"noCaps",attrs:{"block":"","text":""},on:{"click":function($event){return _vm.addEvent(item.value)}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("Task"))+" ")],1)]}}],null,false,1137510048)}):_vm._e(),_c('v-btn',{staticClass:"noCaps",attrs:{"block":"","text":""},on:{"click":function($event){return _vm.addChapter(_vm.i+1)}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t('Topic'))+" ")],1)]},proxy:true},{key:"dialog",fn:function(){return [_c('ws-dialog',{attrs:{"title":_vm.$t('Edit topic')},on:{"save":_vm.addTopic},model:{value:(_vm.displayChapterDialog),callback:function ($$v) {_vm.displayChapterDialog=$$v},expression:"displayChapterDialog"}},[_c('ws-text-field',{attrs:{"label":_vm.$t('Name'),"placeholder":_vm.$t('EnterName')},model:{value:(_vm.entityData.name),callback:function ($$v) {_vm.$set(_vm.entityData, "name", $$v)},expression:"entityData.name"}})],1)]},proxy:true}]),model:{value:(_vm.$store.state.dashboard.pageData),callback:function ($$v) {_vm.$set(_vm.$store.state.dashboard, "pageData", $$v)},expression:"$store.state.dashboard.pageData"}})}
var staticRenderFns = []

export { render, staticRenderFns }